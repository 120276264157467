import { Help } from '@components/ecommerce';
import { Layout } from '@components/layout';
import { PageHeader } from '@components/shared';
import {
  CompaniesFeaturedText,
  CompaniesReviews,
  CompaniesWorth,
  CompaniesTrust,
  Products
} from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface CompaniesPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
  background: string;
}

const CompaniesPage: React.FC<CompaniesPageProps> = ({ pageContext }) => {
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <PageHeader title="Oferta dla firm" />
      <CompaniesFeaturedText />
      <CompaniesWorth />
      <CompaniesReviews />
      <CompaniesTrust />
      <Products background="#F2F2F4" />
      <Help />
    </Layout>
  );
};

export default CompaniesPage;
